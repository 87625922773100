.center {
  margin: auto;
}
.center2 {
  margin: auto;
  display: flex;
  gap: 45px;
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .center2 {
    flex-direction: column;
    gap: 5px;
  }
}
.center,
.center4 {
  margin-bottom: 50px;
}
.center,
.center2,
.center4 p {
  font-size: 18px;
  font-weight: bold;
}
.noundersPage h1,
.noundersPage h2,
.noundersPage h3 {
  font-family: 'Londrina Solid';
}

.noundersPage h2 {
  margin-bottom: 2rem;
}

.noundersPage a {
  color: white;
  font-family: 'Londrina Solid';
  color: var(--lost-dark) !important;
}
.noundersPage a:hover {
  color: var(--lost-red) !important;
}

.bioGroup {
  padding-right: 5px;
  padding-left: 5px;
}
.bioGroup a {
  font-size: 1rem;
}

.noundersPage img {
  border-radius: 50%;
  width: 50%;
}

.marginBottom {
  margin-bottom: 0rem;
}

.bioGroup {
  margin-bottom: 2rem;
  text-align: center;
}
.bioGroup a:hover svg {
  color: var(--lost-red);
}
.bioGroup img {
  margin-bottom: 1rem;
}

.twitterIcon {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.card {
  border: none;
}

.card,
.headerWrapper {
  font-size: 1.3rem;
}
.cardHeader {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

@media (max-width: 767px) {
  .bioGroup {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .bioGroup a {
    font-size: 0.8rem;
  }
  .twitterIcon {
    width: 15px;
  }
}

.center2 img {
  border-radius: inherit !important;
  width: 100%;
}
.questionGrid {
  display: flex;
  gap: 30px;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
}
.question {
  display: flex;
  width: 45%;
  padding: 10px;
  gap: 5px;
  border-radius: 12px;
  flex-direction: column;
}
.questionTop {
  font-family: 'Londrina Solid';
  letter-spacing: 0.05rem;
  font-size: 22px;
}

@media (max-width: 767px) {
  .questionGrid {
    gap: 10px;
    justify-content: space-between;
  }
  .questionTop {
    font-size: 20px;
  }
  /* .question p {
    justify-content: start;
  } */
}
.question {
  width: 100%;
  padding: 0 0 20px 0;
}
.question p {
  margin-bottom: 0;
}
.download {
  text-direction: underline;
  pointer-events: cursor;
  color: '#ff0c0d' !important;
  color: var(--lost-red);
}

.center4 {
  background: var(--lost-tan) !important;
}

.teamCenter {
  background: var(--lost-tan) !important;
  margin: auto;
  padding: 25px 0px;
}
