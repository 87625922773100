.nounWrapper {
  align-self: flex-end;
  width: 100%;
}
.nounContentCol {
  display: flex;
}

.auctionActivityCol {
  padding-top: 2rem;
  background: var(--lost-tan) !important;
  /* background: var(--auction) !important; */
  align-self: start !important; /* maintains auction arrow buttons fixed from auction to auction  */
}

@media (max-width: 992px) {
  .auctionActivityCol {
    background-color: white;
    background: #f9e7c7 !important;
    /* border-bottom: 1px solid var(--lost-tan) !important; */
    padding-bottom: 2rem;
  }
}
