.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
}

.bidRow {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.322);
  font-size: 0.9rem;
  font-weight: 600;
}
.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}
.bidder {
  color: var(--brand-black);
}
.bidDate {
  display: none;
  color: gray;
}
.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bidAmount {
  color: var(--brand-black);
  margin-right: 1rem;
}
.linkSymbol,
.linkSymbol a {
  color: var(--brand-black);
}
.linkSymbol a:hover {
  color: gray;
}

@media (max-width: 992px) {
  .bidRow {
    border-radius: 0px;
  }
}
