.nounTitle {
  color: black;
}

.nounTitle a:link {
  color: black;
}

.nounTitle a:visited {
  color: black;
}

.nounTitle a:hover {
  color: black;
}

.nounTitle:active {
  color: black;
}

.wrapper {
  display: inline-block;
}
.wrapper h1 {
  font-family: 'Londrina Solid' !important;
}
.nounName {
  font-size: 2.25rem;
  margin-top: 0.3rem;
}
