.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 0;
}

.bidRow {
  padding: 1rem;
  border-bottom: 1px solid rgba(211, 211, 211, 0.322);
}
.bidItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftSectionWrapper {
  display: flex;
  flex-direction: column;
}
.bidder {
  font-weight: bold;
  color: var(--brand-black);
}
.bidDate {
  color: gray;
}
.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bidAmount {
  color: var(--brand-black);
  margin-right: 1rem;
  font-size: 1.5rem;
}
.linkSymbol,
.linkSymbol a {
  color: var(--brand-black);
}
.linkSymbol a:hover {
  color: gray;
}
