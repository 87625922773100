.title {
  margin-bottom: 0.5rem;
}

.timerWrapper {
  display: flex;
}

.timerSection {
  margin-right: 0.5rem;
}

.small {
  padding-left: 0.1rem;
  font-size: 0.65em;
}

.timeSubtitle {
  font-size: medium;
}

.clockSection {
  margin-right: 0rem;
  min-width: 250px
}

.auctionTimerSection {
  cursor: pointer;
}
