.img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.imgWrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.traitWrapper {
  position: absolute;
  background: purple;
}