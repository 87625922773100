.navBarBrand {
  position: relative;
  z-index: 2;
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.connectBtn {
  cursor: pointer;
}
.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  cursor: pointer !important;
}
.disconnectBtn {
  cursor: pointer;
}
.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: 5px;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  padding: 0.5rem 0.8rem !important;
  color: var(--brand-black) !important;
  font-size: 1.2rem;
  /* font-size: 1rem; */
  color: white !important;
  color: var(--lost-dark) !important;
  font-family: 'Londrina solid';
}
.nounsNavLink:hover {
  color: var(--lost-red) !important;
  color: #ff1f1f !important;
  /* opacity: 85%; */
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
