:root {
  --brand-bg-green: #edf2f0;
  --lost-red: #d63c5e;
  --brand-light-green: #6da886;
  --brand-black: #212529;

  /* New Colors */
  /* --lost-tan: #202124;
  --lost-red: #ff0c0d; */
  /* // almond */
  /* --lost-tan: #ffebcd; */
  /* // messhup */
  --lost-tan: #f7ddb2;
  --lost-lighttan: #d3d3d3;
  /* dark: #B0B0B0
mid: #D3D3D3
light: #E8E8E8 */

  /* --lost-tan: #dec499; */

  /* --lost-tan: #e0d0b2; */
  --lost-lighttan: #f9e7c7;
  /* --lost-lighttan: #f0f0f0; */
  --auction: #fbfbfd;
  --auction-mid: #f3f3f3;

  --lost-dark: #161616;
  /* old */
  /* --lost-tan: #f7ddb2; */
  --lost-red: #ff0c0d;
}
