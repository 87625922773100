.navArrowsContainer {
  position: absolute;
  /* margin-top: 0.3rem; */
  margin-left: 12.35rem;
}

.leftArrow {
  margin-left: 1rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: #6db3f2;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  font-size: xx-large;
  color: white;
  color: var(--lost-dark) !important;
}
.rightArrow {
  margin-left: 1rem;
  margin-left: 0.6rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  font-size: xx-large;
  color: white;
  color: var(--lost-dark) !important;
}
.leftArrow:hover,
.rightArrow:hover {
  opacity: 0.9;
}
.leftArrow:disabled,
.rightArrow:disabled {
  opacity: 0.5;
}

@media (max-width: 992px) {
  .leftArrow {
    margin-left: 1rem;
  }
}
@-moz-document url-prefix() {
  .navArrowsContainer {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
