@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  font-family: 'Londrina Solid' !important;
  background: #fefefe !important;
  color: #161616 !important;
  border-radius: 15px !important;
  box-shadow: 0 30px 60px -12px rgb(50 50 93 / 25%), 0 18px 36px -18px rgb(0 0 0 / 30%),
    0 -12px 36px -8px rgb(0 0 0 / 3%) !important;
}
.__react_component_tooltip {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 200ms !important;
}

.__react_component_tooltip:hover {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 200ms !important;
}
.question .download {
  text-direction: underline;
  pointer-events: cursor;
  color: '#ff0c0d' !important;
  color: var(--lost-red) !important;
}
.lostHeads {
  background: #f3f3f3;
  /* opacity: 0.5; */
}
