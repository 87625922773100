.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

.copy {
  font-size: 20px;
  font-weight: 500;
  font-weight: bold;
}
.subcopy {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}

@media (max-width: 992px) {
  .wrapper {
    /* padding: 2rem; */
    padding: 0rem;
  }
}

@media (min-width: 992px) {
  .wrapper {
    margin-bottom: 50px;
  }
  .wrapper h1 {
    font-size: 4rem;
    color: var(--lost-dark) !important;
  }
}

.wrapperGrid2 {
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  width: 100%;
}
.wrapperGrid2 img {
  /* width: 25%; */
  height: 200px;
  width: auto;
}
.quote2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
}
.wrapperGrid2 p {
  font-size: 14px;
  font-size: 18px;
}
.wrap {
  display: flex;
  gap: 50px;
  padding: 0px 50px;
  padding: 0px 0px;
  flex-wrap: wrap;
  width: 47%;
}
.both {
  background: var(--lost-tan) !important;
  padding: 50px 100px;
  padding: 50px 0px !important;
}

.credit {
  /* font-weight: nrom; */
  font-size: 32px;
  /* font-style: italic; */
  float: left;
  padding-top: 5px;
  font-family: 'Londrina Solid';
  /* padding-left: 40px; */
}
.notes {
  text-align: center;
  font-family: 'Londrina Solid';
  font-size: 3rem;
  padding-bottom: 30px;
}

.wrapperGrid {
  display: grid;
  margin-bottom: 50px;
  padding: 30px;
  border: 4px solid var(--lost-tan) !important;
  border-bottom: 0px;
  background: var(--lost-tan) !important;
  border-radius: 20px;
}
@media (min-width: 992px) {
  .wrapperGrid {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0px;
    gap: 50px;
  }
  .wrapperGrid img {
    align-self: self-end;
  }
  .quote {
    grid-template-columns: 1fr 1fr;
  }
}

.quote {
  display: grid;
  align-items: center;
  gap: 40px;
}
.quote p {
  font-size: 14px;
  font-weight: 700;
  /* font-style: italic; */
  margin-bottom: 0rem;
}
.spacerDiv {
  height: 20px;
  margin-top: 20px !important;
}
@media (max-width: 991px) {
  .wrapperGrid {
    border-radius: 0px;
    gap: 20px;
    /* padding-top: 0px; */
  }
  .wrapperGrid img {
    width: 40%;
  }
  .quote {
    gap: 0px;
    grid-template-rows: 1fr 1fr !important;
  }
  .quote p {
    font-size: 14px;
  }
  .quotes {
    background: var(--lost-tan) !important;
    margin-bottom: 0px !important;
    padding-top: 20px !important;
  }

  .quote2 {
    flex-direction: column;
  }
  .wrap {
    gap: 25px !important;
    padding: 0px 0px;
    padding: 0px !important;
    flex-direction: column;
  }
  .wrapperGrid2 {
    width: 90%;
    margin: auto;
  }
  .wrapperGrid2 img {
    height: auto;
    margin: auto;
    width: 75%;
    width: 65%;
  }
  .copy {
    font-size: 16px !important;
  }
  .wrapperGrid2 .copy {
    font-weight: 400;
    font-size: 16px !important;
  }
  .credit {
    padding: 0px;
    font-size: 26px;
  }
  .spacerDiv {
    height: 0px;
    margin-top: 0px !important;
  }
  .bannerSection {
    padding-bottom: 0px !important;
  }
  .noundersDev {
    padding-top: 40px !important;
  }
  h1 {
    font-size: 2rem !important;
  }
}

.noundersDev {
  padding: 0;
}
.wrap {
  display: flex !important;
  width: 100% !important;
  flex-wrap: wrap !important;
}
.wrapperGrid2 {
  width: 47% !important;
}
@media (max-width: 991px) {
  .wrapperGrid2 {
    width: 85% !important;

    width: 90% !important;
  }
}
