.headerRow {
  margin: 2rem 0;
}
.headerRow span {
  color: #8c8d92;
  color: white;
  color: var(--lost-dark) !important;
  font-size: 34px;
  font-family: 'Londrina Solid';
}

.headerRow h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.headerRow p {
  color: #14161b;
  color: white !important;
  color: var(--lost-dark) !important;
  font-weight: bold;
}
.headerRow a {
  color: #ff0c0d !important;
}
.headerRow a:hover {
  color: grey !important;
}

.generateBtn {
  width: 100%;
  height: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  /* background-color: var(--lost-red); */
  background-color: #ff0c0d;
  border: var(--lost-red);
  font-family: 'Londrina Solid';
  font-size: 26px !important;
}
@media (max-width: 767px) {
  .generateBtn {
    height: 2rem;
    font-size: 14px !important;
  }
}

.generateBtn:focus,
.generateBtn:hover {
  /* background-color: var(--lost-red) !important; */
  background-color: #ff0c0d !important;
  /* opacity: 85%; */
  /* opacity: 100% !important; */
  /* transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s; */
}
.generateBtn:active {
  /* background-color: var(--lost-red) !important; */
  /* transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s; */
  background-color: #ff0c0d !important;
  /* opacity: 100% !important; */
}
.traitForm {
  height: 4rem;
}
.traitFormBtn {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.traitFormBtn:hover,
.traitFormBtn:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.floatingLabel {
  font-size: 15px;
  color: #8c8d92;
}
@media (max-width: 767px) {
  .floatingLabel {
    font-size: 12px;
    font-weight: bold;
  }
  .traitForm {
    width: 48% !important;
    height: 2.5rem;
  }
  .traitForm:last-child {
    height: 4rem;
  }
}
.nounYearsFooter {
  font-style: italic;
  font-family: 'Londrina solid';
}
.nounYearsFooter span {
  color: #ff0c0d !important;
}
@media (max-width: 767px) {
  .nounYearsFooter {
    padding: 0;
  }
  .headerRow {
    margin: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .headerRow span {
    font-size: 26px;
  }
  .headerRow p {
    font-size: 12px;
    padding: 0;
    padding-top: 10px;
  }
}
.nounImg {
  border-radius: 16px;
}
.nounImg:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.nounWrapper {
  margin-bottom: 1rem;
}

/* .generateBtn .generateBtn:hover .generateBtn:focus .generateBtn:active {
  opacity: 100% !important;
} */
