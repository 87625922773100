@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}
.font-nouns {
  font-family: 'Londrina Solid';
}

.navbar .nav-item {
  font-family: 'LONDRINA SOLID' !important;
  /* font-size: 1.2rem !important; */
  font-size: 1rem !important;
  color: var(--lost-dark) !important;
}
.navbar-toggler-icon {
  filter: invert(99%) sepia(4%) saturate(279%) hue-rotate(355deg) brightness(118%) contrast(100%);
  filter: var(--lost-dark) !important;
}

.footerText {
  margin: 0 5px;
  color: var(--lost-dark) !important;
}

@media (min-width: 768px) {
  .m {
    display: none;
  }
}
@media (max-width: 767px) {
  .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* height: 215px; */
    gap: 10px !important;
    /* grid-template-column: 100px 100px; */
  }
  .traitForm {
    width: 48% !important;
  }
  .form-floating > .form-select {
    padding-top: 1.25rem !important;
    padding-bottom: 0.25rem !important;
    cursor: pointer !important;
  }
  .form-floating select {
    font-size: 12px !important;
  }

  .homeLogo {
    width: 145px;
    height: 45px;
  }
  .navbar-brand {
    margin-right: 0rem !important;
  }
  .navbar .nav-item {
    display: none;
  }
  .d {
    display: none;
  }
}
.navbar-light .navbar-toggler {
  color: white !important;
  color: var(--lost-dark) !important;
}

.navbar,
.navbar-expand-lg,
.navbar-light {
  background-color: var(--lost-tan) !important;
  /* background-color: #b0b0b0 !important; */
}

/* .container, */
.row > * {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.lost {
  /* color: var(--lost-red) !important; */
  font-family: 'LONDRINA SOLID' !important;
  letter-spacing: 0.05rem;
}
/* auction bg */
.bg {
  background: var(--lost-tan) !important;
  /* background: var(--auction) !important; */
  /* padding-bottom: 2rem; */
  padding-top: 3rem;
}
@media (max-width: 767px) {
  .bg {
    padding-top: 0rem;
  }
}
.gr {
  width: 100%;
  padding: 0px;
}

h1,
h2,
h3,
h4,
p {
  color: var(--lost-dark) !important;
}

footer {
  color: var(--lost-dark) !important;
}

.homeLogo {
  width: auto;
  height: 45px;
}
@media (max-width: 767px) {
  .homeLogo {
    width: auto;
    height: 25px;
  }
}
@media (max-width: 991px) {
  .quotes {
    background: var(--lost-tan) !important;
    margin-bottom: 0px !important;
    padding-top: 20px !important;
  }
}
/* 
.generateBtn .generateBtn:hover .generateBtn:focus .generateBtn:active {
  opacity: 100% !important;
} */

p {
  font-family: 'PT Root UI';
}
